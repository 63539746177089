*{
    overflow-x: hidden;
}
.container1 {
    display: flex;
    margin-top: 7%;
}

.heading {
    margin-left: 50px;
    padding-top: 110px;
    width: 60%;
    font-family: Arial, sans-serif;   
}

#h1 {
    font-size: 40px;
    font-weight: 700;
    color: rgb(129, 129, 218);
}

#h2 {
    font-size: 20px;
    font-weight: 500;
    color: rgb(104, 104, 134);
}

#h3 {
    font-size: 15px;
    font-weight: 800;
    color: rgb(94, 94, 141);
}

#h1,
#h2,
#h3 {
    animation: fadeInScale 1s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

#btn {
    animation: btn 1s ease-in-out;
}

@keyframes btn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.img1 {
    height: inherit;
    width: 100%;
    overflow: hidden;
    animation: fadeInimg 1s ease-out forwards; 
}
@keyframes fadeInimg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

@media (max-width: 991px) {
    .container1 {
        position: relative;
        overflow: hidden;
        margin-top: 0px;
    }
     .img1::before{
        opacity: 0.8;
     }
    .img1 {
        padding-left: 0px;
        background-size: cover;
        background-position: center;
        height: 100vh;
        /* Adjust the height as needed */
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .heading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        padding-top: 30px;
        width: 90%;
        background-color: rgba(240, 248, 255, 0.815);
        border-radius: 30px;
    }
    #a,
    #b,
    #c {
        background-color: rgba(225, 221, 236, 0.795);
        border-radius: 10px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;   
    }

    #h1 {
        font-size: 45px;
        font-weight: 700;
        color: rgb(98, 98, 182);
    }

    #h2 {
        font-size: 25px;
        font-weight: 500;
        color: rgb(97, 97, 145);
    }

    #h3 {
        font-size: 18px;
        font-weight: 800;
        color: rgb(78, 78, 112)
    }
    @keyframes fadeInimg {
        from {
        }
        to {
        }
      }
}


.features {
    font-family: "Archivo Black", sans-serif;
    background: #f0f2f8;
    display: flex;
    flex-direction: column;
    height: 20%;
    justify-content: center;
    align-items: center;
  }
  
  .words {
    color: #a3a2da;
    font-size: 0;
    line-height: 1.5;
  }
  
  .words span {
    font-size: 3rem;
    display: inline-block;
    animation: move 4s ease-in-out infinite;
    width: 12%;
  }
  
  @keyframes move {
    0% {
      transform: translate(-30%, 0);
    }
    50% {
      text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75);
    }
    100% {
      transform: translate(30%, 0);
    }
  }
  
  .words span:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .words span:nth-child(3) {
    animation-delay: 1s;
  }
  
  .words span:nth-child(4) {
    animation-delay: 1.5s;
  }
  
  .words span:nth-child(5) {
    animation-delay: 2s;
  }
  
  .words span:nth-child(6) {
    animation-delay: 2.5s;
  }
  
  .words span:nth-child(7) {
    animation-delay: 3s;
  }
